import React, { useState, useEffect } from "react";
import useLocalStorage from "react-use-localstorage";
import styled from "styled-components";
import { Button, Card } from "antd";
import sample from "lodash/sample";
import findIndex from "lodash/findIndex";
import shuffle from "lodash/shuffle";
import isString from "lodash/isString";

import background from "./background.jpg";

const __DEV__ = process.env.NODE_ENV === "development";

let drawing = [];
if (__DEV__) {
  drawing = shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8]);
} else {
  drawing = require("./drawing");
}

const Screen = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background-color: #f5f4f2;
  background-image: url(${background});
  background-position: center;
  background-size: cover;
  text-align: center;
  padding: 10%;

  .buttons {
    max-width: 500px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  button {
    margin: 10px;
  }

  .ant-card {
    margin-top: 20px;
    max-width: 500px;
  }

  p {
    display: block;
    h2 {
      display: inline;
    }
  }
`;

const Wishlist = styled.p`
  font-family: Georgia, serif;
  font-style: italic;
  font-size: 20px;
  quotes: "“" "”";

  &:before {
    content: open-quote;
  }

  &:after {
    content: close-quote;
  }
`;

const Span = styled.span`
  display: inline-block;
  min-width: 30px;
  text-align: center;
`;

const Link = styled.a.attrs({
  target: "_blank",
  rel: "noopener noreferer"
})`
  text-decoration: underline;
`;

const Emoji = () => {
  const [emoji] = useState(
    sample([
      "🎄",
      "🤶🏻",
      "🎅🏻",
      "⛄️",
      "❄️",
      "🎁",
      "🥂",
      "🦌",
      "🍷",
      "🍪",
      "🔔",
      "🕯",
      "🎉",
      "🎊"
    ])
  );

  return (
    <Span role="img" aria-label="Christmas">
      {emoji}
    </Span>
  );
};

const App = () => {
  const [user, setUser] = useLocalStorage("user");
  const [victim, setVictim] = useState(null);
  const [participants] = useState([
    {
      name: "André",
      wishlist:
        "Een boek is leuk, bijv. How to Invent Everything: A Survival Guide for the Stranded Time Traveler of A Short History of Nearly Everything zoiets. (engels of nl). (Bier is ook goed 🍻)"
    },
    { name: "Christian", wishlist: null },
    {
      name: "Ella",
      wishlist: () => (
        <>
          <Link href="https://www.blokker.nl/p/mouthguard-challenge-spel/1433374">
            Mouthguard challenge spel
          </Link>
          ,{" "}
          <Link href="https://www.levenzonderafval.com/a-53763697/haarverzorging/tester-dode-zee-modder-haarzeep-van-helemaal-shea/">
            Tester dode zee modder zeep
          </Link>
          ,{" "}
          <Link href="https://www.levenzonderafval.com/a-52476747/haarverzorging/brandnetel-rozemarijn-shampoo-bar-van-helemaalshea-100-gram-normaal-vet-haar/">
            Brandnetel rozemarijn shampoo bar
          </Link>
          ,{" "}
          <Link href="https://www.levenzonderafval.com/a-53727476/mondverzorging/natuurlijke-tandpasta-actieve-kool-van-georganics-60-ml/">
            Natuurlijke tandpasta
          </Link>
          ,{" "}
          <Link href="https://www.levenzonderafval.com/a-49038456/mondverzorging/bamboe-tandenborstel-next-brush-soft/">
            Bamboe tandenborstel
          </Link>
        </>
      )
    },
    { name: "Jantine", wishlist: "Rituals" },
    { name: "John", wishlist: "Dikke Xbox One 🎮 met NBA 2k18!" },
    {
      name: "Lucia",
      wishlist: () => (
        <>
          💄{" "}
          <Link href="https://www.levenzonderafval.com">Bio/eco cosmetics</Link>
          , 📚 book (sport, biography), 🍫 chocoladeletter, 🚰 metalen drinkfles
          (not Dopper)
        </>
      )
    },
    { name: "Nico", wishlist: null },
    { name: "Pim", wishlist: null },
    {
      name: "Wilja",
      wishlist:
        "Een extra bakje voor alle medailles 🏅🥇, geurkaarsen, shampoo bar van Lush"
    }
  ]);

  useEffect(
    () => {
      if (user) {
        const index = findIndex(participants, { name: user });

        if (index !== -1) {
          const victim = participants[drawing[index]];
          setVictim(victim);
        } else {
          setUser("user", null);
        }
      }
    },
    [user]
  );

  return (
    <Screen>
      <h1>Lootjes trekken!</h1>
      <h3>Ik ben</h3>

      <div className="buttons">
        {participants.map(participant => (
          <Button
            key={participant.name}
            type={participant.name === user ? "primary" : "default"}
            disabled={!__DEV__ && !!user && participant.name !== user}
            size="large"
            onClick={() => setUser("user", participant.name)}
          >
            <Emoji /> {participant.name} <Emoji />
          </Button>
        ))}
      </div>
      {!!user && !!victim && (
        <Card>
          <p>
            <strong>{user}</strong>
          </p>
          <p>jij moet een gedichtje schrijven en cadeautje (~€15) kopen voor</p>
          <h2>
            <Emoji />
            <Emoji /> {victim.name} <Emoji />
            <Emoji />
          </h2>
          {!!victim.wishlist && (
            <Wishlist>
              {isString(victim.wishlist) ? victim.wishlist : victim.wishlist()}
            </Wishlist>
          )}
        </Card>
      )}
    </Screen>
  );
};

export default App;
